<template>
  <div
    v-if="methods.length > 0"
    class="flex justify-center"
  >
    <div v-for="item in methods" :key="`method_${item}`">
      <img :src="getImageUrl(item)" :alt="item" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CreditCardType } from '@shared/elements/common/payment/types';
import { getAssetFromCdn } from '@shared/utils/helpers';

const { VISA, MASTERCARD, AMERICAN, MNP, JCB, DISCOVER, DINERS } = CreditCardType;
const methods = [VISA, MASTERCARD, AMERICAN, MNP, JCB, DISCOVER, DINERS];

function getImageUrl(name: string) {
  return getAssetFromCdn(`img/payment-methods/${name}.png`);
}
</script>
